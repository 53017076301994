// 满减满折
import { request as axios } from '@/util/request';

const getUrl = require('baimahu/url-with-param');
const API = {
  list: '/boss/marketing/discounts', // 列表
  add: '/boss/marketing/discounts', // 新建
  product_list: '/boss/marketing/discounts/product', // 商品列表
  edit_product: '/boss/marketing/discounts/goods', // 编辑-商品列表
  edit_detail: '/boss/marketing/discounts/detail', // 编辑-详情
};
// 获取列表
export async function getDiscountList(query_params: any) {
  const url = getUrl(API.list, { ...query_params });
  return await axios.get(url);
}

// 新建
export async function addDiscount(params) {
  const url = '/boss/marketing/discounts';
  return await axios.post(url, params);
}

// 编辑
export async function editDiscount(id, params) {
  const url = `/boss/marketing/discounts/${id}`;
  return await axios.put(url, params);
}

// 停用
export async function removeDiscount(id) {
  const url = `/boss/marketing/discounts/status/${id}`;
  return await axios.put(url);
}
// /boss/marketing/discounts/status
// 批量停用
export async function removeMoreDiscount(params) {
  const url = `/boss/marketing/discounts/status`;
  return await axios.post(url, params);
}
// 商品列表
export async function getProductList(query_params: any) {
  const url = getUrl(API.product_list, { ...query_params });
  return await axios.get(url);
}

// 编辑-满减满折列表
export async function getEditProductList(query_params: any) {
  const url = getUrl(API.edit_product, { ...query_params });
  return await axios.get(url);
}

// 编辑-删除商品
export async function removeProduct(id, params) {
  const url = `/boss/marketing/discounts/goods/${id}`;
  return await axios.delete(url, { data: params });
}

// 编辑
export async function editProductSort(id, sort: number) {
  const url = `/boss/marketing/discounts/goods/${id}`;
  return await axios.put(url, { sort });
}
// 编辑-添加商品
export async function addProduct(params) {
  const url = '/boss/marketing/discounts/goods';
  return await axios.post(url, params);
}

// 编辑-详情
export async function getEditDetail(params: any) {
  const url = getUrl(API.edit_detail, { ...params });
  return await axios.get(url);
}
